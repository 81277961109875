* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', sans-serif;
}

body {
  background: black;
}
