/* HeroSection.css */

.hero-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    #000000 0%,
    #0a0019 35%,
    #0d001f 50%,
    #0a0019 65%,
    #000000 100%
  );
  position: relative;
}

/* Chrome-style title effect */
.company-name {
  color: transparent;
  background: linear-gradient(
    45deg,
    #f0f0f0 0%,
    #ffffff 25%,
    #e6e6e6 50%,
    #ffffff 75%,
    #f0f0f0 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  text-shadow: 
    0 0 10px rgba(255,255,255,0.3),
    0 0 20px rgba(255,255,255,0.2),
    0 0 30px rgba(255,255,255,0.1);
  font-size: 5rem;
  margin-top: 0;
  font-weight: 300;
  letter-spacing: 2px;
  font-family: 'Miller Display', serif;
  position: relative;
}

.company-subtitle {
  color: #b3e6ff;
  font-size: 1.5rem;
  margin-top: 5px;
  font-weight: 300;
  letter-spacing: 2px;
  font-family: 'DM Serif Text', serif;
  opacity: 0.9;
  margin-bottom: 10px;
  text-shadow: 
    0 0 5px rgba(179,230,255,0.5),
    0 0 10px rgba(179,230,255,0.3);
}

.icons-container {
  display: none;
}

.icon-link {
  text-decoration: none;
  color: white;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: float 3s ease-in-out infinite;
}

.eyes-icon {
  animation-delay: 0s;
}

.mouth-icon {
  animation-delay: 1.5s;
}

.icon:hover {
  color: #00f7ff;
  transform: translateY(-5px);
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.eye-image {
  width: 24rem;
  height: 24rem;
  object-fit: contain;
  filter: drop-shadow(0 0 0 rgba(0, 247, 255, 0));
  transition: all 0.3s ease;
}

.lips-image {
  width: 12rem;
  height: 12rem;
  object-fit: contain;
  filter: drop-shadow(0 0 0 rgba(0, 247, 255, 0));
  transition: all 0.3s ease;
  margin-top: -80px;
}

/* Holographic glow effect for icons */
.icon:hover .eye-image,
.icon:hover .lips-image {
  filter: 
    drop-shadow(0 0 4px rgba(0, 247, 255, 0.8))
    drop-shadow(0 0 8px rgba(179, 0, 255, 0.6))
    drop-shadow(0 0 12px rgba(255, 0, 242, 0.4));
  transform: scale(1.05);
}

/* Add a subtle scanline effect */
.hero-container::after {
  content: none;
}

/* Add responsive styles */
@media screen and (max-width: 960px) {
  .company-name {
    font-size: 3.5rem;
    text-align: center;
    margin-top: 20px;
  }

  .company-subtitle {
    font-size: 1.2rem;
    text-align: center;
    padding: 0 20px;
  }

  .eye-image {
    width: 16rem;
    height: 16rem;
  }

  .lips-image {
    width: 8rem;
    height: 8rem;
  }

  .icons-container {
    margin-top: 20px;
    gap: 20px;
  }
}

/* Even smaller screens */
@media screen and (max-width: 480px) {
  .company-name {
    font-size: 2.5rem;
  }

  .company-subtitle {
    font-size: 1rem;
  }

  .eye-image {
    width: 12rem;
    height: 12rem;
  }

  .lips-image {
    width: 6rem;
    height: 6rem;
  }

  .icons-container {
    margin-top: 10px;
    gap: 10px;
  }

  .hero-container {
    background: linear-gradient(
      135deg,
      #000000 0%,
      #0a0019 50%,
      #000000 100%
    );
  }
}

/* Add new contact button styles */
.contact-button {
  margin-top: 40px;
  padding: 15px 30px;
  font-family: 'Miller Display', serif;
  font-size: 1.2rem;
  color: white;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}

.contact-button:hover {
  border-color: #00f7ff;
  color: #00f7ff;
  box-shadow: 0 0 20px rgba(0, 247, 255, 0.3);
  transform: translateY(-2px);
}
