@font-face {
  font-family: 'Miller Display';
  src: url('./fonts/miller-display-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DM Serif Text';
  src: url('./fonts/DMSerifText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} 